import React from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { init } from '../lib/sentry';
import '../styles.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  init();
}

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function MyApp({ Component, pageProps, err }: AppProps) {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      import('better-dateinput-polyfill/dist/better-dateinput-polyfill');
    }
  }, []);

  return (
    <>
      <Head>
        <title>Bloopex | Soluciones de procura</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="title" content="Bloop | Organización de eventos" />
        <meta
          name="description"
          content="En Bloopex optimizamos las compras de tu empresa a traves de un servicio que facilita los procesos de licitación, aprobación y selección de proveedores."
        />
        <meta
          name="keywords"
          content="Compras, e-procurment, alimentos, regalos corporativos, uniformes, textiles, tecnología, computación, artículos de oficina, salud, seguridad, seguridad industrial, audiovisual, eventos, material pop, limpieza, transporte, corporativo, purchasing, proceso de licitación, licitación, suministros, cadena de suministros, abastecimiento "
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://bloopex.com" />
        <meta
          property="og:title"
          content="Bloop | Organización de eventos"
          key="ogtitle"
        />
        <meta
          property="og:description"
          content="A través de aliados estratégico, facilitamos la planificación de tu próximo evento. Compara, aprueba y disfruta con Bloop!"
          key="ogdesc"
        />
        <meta property="og:image" content="/avatar-bloopex.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://bloopex.com" />
        <meta
          property="twitter:title"
          content="Bloop | Organización de eventos"
        />
        <meta
          property="twitter:description"
          content="A través de aliados estratégico, facilitamos la planificación de tu próximo evento. Compara, aprueba y disfruta con Bloop!"
        />
        <meta property="twitter:image" content="/avatar-bloopex.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
      </Head>
      <Component {...pageProps} err={err} />
    </>
  );
}

export default MyApp;
